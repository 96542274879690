<template>
  <div class="customer-account">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchCustomerAccounts"/>
    <app-block-flex>
      <Tooltip placement="top"
               max-width="200"
               content="请先搜索归属门店并选择换绑的消费者账号" :disabled="!allowedRebind">
        <Button
          v-if="userType === 'ADMIN'"
          type="primary"
          :disabled="allowedRebind"
          @click="() => onCustomAction(CUS_ACTION.CREATE, true)">
          换绑门店
        </Button>
      </Tooltip>
      <app-export-bar
        @onExport="onExport"
        @onExportAll="onExportAll"/>
    </app-block-flex>
    <app-data-table
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      :selectable="userType === 'ADMIN'"
      :columns="realCustomerColumns"
      :data="customerAccounts"
      v-model="queryData"
      @onSelect="onSelect"
      @onPageConfigChange="fetchCustomerAccounts">
      <template
        slot-scope="{ row }"
        slot="type">
        {{ ACCOUNT_TYPE_TEXT[row.type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="sex">
        {{ SEX_TEXT[row.sex] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="member_num">
        <el-button type="text" @click="() => onShowMember(row.id)">{{ row.member_num }}</el-button>
      </template>
      <template
        slot-scope="{ row }"
        slot="time_freq_card_num">
        <el-button type="text" @click="() => onShowTimeFreqCard(row.id)">
          {{ row.time_freq_card_num }}{{ '张' }}
        </el-button>
      </template>
      <template
        slot-scope="{ row }"
        slot="jxw_vip_expire_time">
        <div v-if="!row.jxw_vip_expire_time">否</div>
        <div v-else-if="new Date() > new Date(row.jxw_vip_expire_time)">
          <span>{{ row.jxw_vip_expire_time }}</span>
          <span style="border:1px solid red;color: red;padding: 1px;margin-left: 5px;">过期</span>
        </div>
        <div v-else>
          {{ `有效期至${row.jxw_vip_expire_time}` }}
        </div>
      </template>
      <app-spacing
        spacing="sm"
        slot-scope="{ row }"
        slot="action">
        <Button
          type="primary"
          v-if="permissions['account:consumer:edit']"
          @click="() => onCustomAction(CUS_ACTION.EDIT, true,row.id,row)">
          编辑
        </Button>
        <Button
          type="primary"
          v-if="permissions['account:consumer:store']"
          @click="() => showEditCustomerStore(row)">
          归属门店
        </Button>
        <!-- <Button
          type="primary"
          v-if="permissions['account:customer:train:record']"
          @click="() => onCustomAction(CUS_ACTION.REPORT, true,row.id)">
          训练报告
        </Button> -->
        <Button
          type="primary"
          v-if="permissions['account:consumer:prescription:edit']"
          @click="() => onEditPrescription(row.id)">
          修改处方
        </Button>
        <Button
          v-if="permissions['account:consumer:status-toggle']"
          :type="row.status === STATUS.ENABLE ? 'error' : 'primary'"
          @click="() => onCustomAction(CUS_ACTION.TOGGLESTATUS, true,row.id,row)">
          {{ REVERSE_STATUS_TEXT[row.status] }}
        </Button>
      </app-spacing>
    </app-data-table>
    <app-modal
      :width="800"
      :value="customConfig.visible"
      :title="deviceActionText"
      @on-ok="()=> onModalConfirm()"
      @on-cancel="() => onCustomAction(null, false)">
      <Form
        ref="cusForm"
        :label-width="80"
        :model="customModalData"
        :rules="customModalRule">
        <template v-if="customConfig.action ===CUS_ACTION.CREATE">
          <FormItem
            label="门店"
            prop="storeId">
            <Select
              v-model="customModalData.storeId" filterable>
              <Option
                v-for="option in belongStores"
                :value="option.value"
                :key="option.value">
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
        </template>
        <template v-if="customConfig.action === CUS_ACTION.EDIT">
          <FormItem
            label="用户名"
            prop="nickName">
            <Input
              v-model="customModalData.nickName" maxlength="11"/>
            <!-- <span>{{ customModalData.nickName }}</span> -->
          </FormItem>
          <FormItem
            label="手机号"
            prop="mobile">
            <Input
              v-model="customModalData.mobile"
              maxlength="11"/>
          </FormItem>
          <FormItem
            label="性别"
            prop="sex">
            <span>{{ SEX_TEXT[customModalData.sex] }}</span>
          </FormItem>
          <FormItem
            label="生日"
            prop="birthday">
            <span>{{ customModalData.birthday || '未知' }}</span>
          </FormItem>
          <FormItem
            label="状态"
            prop="status">
            <span>{{ STATUS_TEXT[customModalData.status] }}</span>
          </FormItem>
          <FormItem
            label="创建时间"
            prop="createTime">
            <span>{{ customModalData.createTime }}</span>
          </FormItem>
          <FormItem
            label="允许创建的家庭成员数量"
            prop="memberLimit"
            :label-width="180">
            <InputNumber
              :max="10"
              :min="1"
              v-model="customModalData.memberLimit"/>
          </FormItem>
        </template>
        <template v-if="customConfig.action === CUS_ACTION.TOGGLESTATUS">
          <p>停用后，该用户的帐号将不能再登录使用用户端app，是否继续操作？</p>
        </template>
        <template v-if="customConfig.action === CUS_ACTION.PARENTSTORE">
          <Table
            :columns="columnsArr"
            :data="belongModalStores">
            <template
              slot-scope="{ row ,index}"
              :index="index"
              slot="slotName">
              {{ index + 1 }}
            </template>
          </Table>
        </template>
        <template v-if="customConfig.action === CUS_ACTION.REPORT">
          <app-spacing>
            <DatePicker type="daterange" :options="quicklyChange"
                        placement="bottom-end"
                        style="width: 200px"
                        v-model="date"
                        @on-change="(values) => onChangeDate(values)"/>
            <Button
              type="primary"
              @click="() => getRecordList(customModalData.id)">
              搜索
            </Button>
          </app-spacing>
          <Table
            :columns="reportColumnsArr"
            :data="trainRecord">
            <template
              slot-scope="{ row ,index}"
              slot="slotName">
              {{ index + 1 }}
            </template>
            <template
              slot="action"
              slot-scope="{ row }">
              <Button
                type="primary"
                @click="() => onViewReport(row.id)">
                查看报告
              </Button>
            </template>
          </Table>
        </template>
      </Form>

    </app-modal>
    <edit-prescription
      :visible.sync="editPrescriptionVisible"
      :customId="customId"
      :prescriptionsList="prescriptionsList"
      @on-cancel="onCancel"/>

    <!-- 家庭子账号列表 -->
    <el-dialog
      width="80%"
      :visible.sync="familyMemberConfig.visible"
      title="关联使用用户"
      :modal-append-to-body="false"
      :append-to-body="true"
      :destroy-on-close="true"
      @on-cancel="() => onFamilyMemberModalCancel()"
      z-index="10">
      <el-table
        :data="familyMembers"
        stripe
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="nick_name"
          label="用户名">
        </el-table-column>
        <el-table-column
          label="性别">
          <template
            slot-scope="scope">
            {{ SEX_TEXT[scope.row.sex] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sex"
          label="年龄">
          <template
            slot-scope="scope">
            {{ convertToAge(scope.row.birthday) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="relation"
          label="关系">
          <template
            slot-scope="scope">
            {{ RELATION_TEXT[scope.row.relation] }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="300">
          <template slot-scope="scope">
            <app-spacing
              spacing="sm"
              slot="action">
              <Button
                type="primary"
                v-if="permissions['account:consumer:edit']"
                @click="() => onFamilyMemberEdit(scope.row)">
                编辑
              </Button>
              <Button
                type="primary"
                v-if="permissions['account:consumer:prescription:edit']"
                @click="() => onFamilyMemberPrescriptionEdit(scope.row.id)">
                修改处方
              </Button>
              <Button
                type="error"
                v-if="permissions['account:consumer:prescription:edit']"
                @click="() => onFamilyMemberDelete(scope.row)">
                删除
              </Button>
            </app-spacing>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="800"
      :visible.sync="familyMemberConfig.editVisible"
      title="编辑"
      :modal-append-to-body="false"
      :append-to-body="true"
      :destroy-on-close="true">
      <Form
        ref="familyMemberForm"
        :label-width="80"
        :model="familyMemberModalData"
        :rules="familyMemberModalRule">
          <FormItem
            label="用户名"
            prop="nick_name">
            <Input
              v-model="familyMemberModalData.nick_name" maxlength="11"/>
          </FormItem>
          <FormItem
            label="性别"
            prop="sex">
            <el-select v-model="familyMemberModalData.sex" placeholder="请选择性别">
              <el-option label="女" :value="0"></el-option>
              <el-option label="男" :value="1"></el-option>
            </el-select>
          </FormItem>
          <FormItem
            label="生日"
            prop="birthday">
            <el-date-picker
                :z-index="100"
                type="date"
                placeholder="选择日期"
                v-model="familyMemberModalData.birthday"
                style="width: 100%;"
                value-format="yyyy-MM-dd"
                :clearable="false">
              </el-date-picker>
          </FormItem>
          <FormItem
            label="关系"
            prop="sex">
            <Select
                v-model="familyMemberModalData.relation">
                <Option
                  v-for="item in RELATION_TYPE_OPTIONS"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
          </FormItem>
          <FormItem
            label="创建时间"
            prop="create_time">
            <span>{{ familyMemberModalData.create_time }}</span>
          </FormItem>
      </Form>
      <template slot="footer" class="dialog-footer">
        <Button style="margin-right:16px;" @click="familyMemberConfig.editVisible = false">
          取消
        </Button>
        <Button type="primary" @click="onFamilyMemberModalConfirm()">确定</Button>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="familyMemberConfig.deleteVisible"
      title="删除子账号">
      <p>
        {{`是否确认删除该子账号:${familyMemberConfig.deleteNickName}`}}
      </p>
      <template slot="footer" class="dialog-footer">
        <Button style="margin-right:16px;" @click="familyMemberConfig.deleteVisible = false">
          取消
        </Button>
        <Button type="primary" @click="onFamilyMemberModalDeleteConfirm()">确定</Button>
      </template>
    </el-dialog>

    <!-- 时效次卡列表 -->
    <el-dialog
      width="500px"
      :visible.sync="timeFreqCardConfig.visible"
      title="时效卡"
      :modal-append-to-body="false"
      :append-to-body="true"
      :destroy-on-close="true"
      @on-cancel="() => {timeFreqCardConfig.visible = false}">

      <div v-for="item in timeFreqCards" :key="item.order_no" style="">
        <div style="
              padding: 10px 0 10px 0;
              border-bottom: 1px solid #000;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              height: 80px;
             ">
          <Row style="padding: 0 20px;
                display: flex;
                justify-content: space-between;
                font-size: 15px;">
            <Col span="12" style="font-weight: bold;">{{ `卡号${item.order_no}` }}</Col>
            <Col span="12">{{ `失效日期${item.end_time}` }}</Col>
          </Row>
          <Row style="padding: 0 20px;
                display: flex;
                justify-content: space-between;
                font-size: 15px;">
            <Col span="12">{{ `${item.channel_name}-${item.store_name}` }}</Col>
            <Col span="12">
              {{
                `
                剩余次数${item.num - item.use_num}次,
                ${item.status === -1 ? '解绑失效' : (item.status === 0 ? '已失效' : '生效中' )}
                `
              }}
            </Col>
          </Row>
        </div>
      </div>

    </el-dialog>

    <edit-customerStore
      :visible.sync="editCustomerStoreVisible"
      :customer="editCustomerStoreRow"
      @on-cancel="() => {editCustomerStoreVisible = false}"/>
  </div>
</template>

<script>
import moment from 'moment';
import { USER_TYPE } from '../../login/constants';
import {
  CUSTOMER_COLUMNS,
  ACCOUNT_TYPE_TEXT,
  SEX_TEXT,
  STATUS,
  STATUS_TEXT,
  REVERSE_STATUS_TEXT,
  CUS_ACTION,
  CUS_ACTION_TEXT,
  ALLOWED_PERMISSION,
  columnsArr,
  reportColumnsArr,
  RELATION_TEXT,
  RELATION_TYPE_OPTIONS,
} from './constants';
import EditPrescription from './edit-prescription.component.vue';
import StoreManageApi from '@/pages/business/store-manage/store-manage.api';
import CustomerAccountManageApi from './customer-account-manage.api';
import DataTransferUtil from '@/utils/data-transfer.util';
import { TIPS } from '@/pages/business/store-manage/constants';
import EditCustomerStore from './edit-customer-store.vue';

export default {
  name: 'customer-account-manage',
  components: {
    EditPrescription,
    EditCustomerStore,
  },
  computed: {
    allowedRebind() {
      const { selectStoreIds, bindStoreId } = this.customModalData;
      return !((bindStoreId?.toString())
        && selectStoreIds.length !== 0);
    },
    realCustomerColumns() {
      const columns = [...CUSTOMER_COLUMNS];

      if (this.$store.getters.userType === USER_TYPE.CHANNEL) {
        columns.splice(columns.findIndex((column) => column.key === 'remain_freq_card'), 1);
      }
      const allowedPermissionValues = Object.values(ALLOWED_PERMISSION);
      // eslint-disable-next-line max-len
      const count = Object.keys(this.permissions).filter((k) => allowedPermissionValues.includes(k)).length;
      columns.map((c) => {
        if (c.slot === 'action') {
          // eslint-disable-next-line no-param-reassign
          c.width = count * 90;

          if (count === 1) {
            // eslint-disable-next-line no-param-reassign
            c.width = count * 120;
          }
        }
        return c;
      });
      return columns;
    },
    deviceActionText() {
      return CUS_ACTION_TEXT[this.customConfig.action];
    },
    userType() {
      return this.$store.getters.userType;
    },
    queryConfig() {
      return [
        {
          fieldName: 'nickName',
          name: '用户名',
        },
        {
          fieldName: 'mobile',
          name: '手机号',
        },
        {
          fieldName: 'storeId',
          name: '归属门店',
          type: 'select',
          options: this.belongStores,
        },
        {
          fieldName: 'type',
          name: '账号类型',
          type: 'select',
          options: this.belongType,
        },
        {
          fieldName: 'hasTimeCard',
          name: '时效卡',
          type: 'select',
          options: [
            {
              value: 0,
              label: '无',
            }, {
              value: 1,
              label: '有',
            },
          ],
        },
        {
          fieldName: 'memberShip',
          name: '会员',
          type: 'select',
          options: [
            {
              value: 0,
              label: '未过期',
            },
            {
              value: 1,
              label: '已过期',
            },
            {
              value: 2,
              label: '非会员',
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      ALLOWED_PERMISSION,
      CUS_ACTION_TEXT,
      CUS_ACTION,
      CUSTOMER_COLUMNS,
      ACCOUNT_TYPE_TEXT,
      SEX_TEXT,
      RELATION_TEXT,
      RELATION_TYPE_OPTIONS,
      STATUS,
      STATUS_TEXT,
      REVERSE_STATUS_TEXT,
      columnsArr,
      reportColumnsArr,
      belongStores: [],
      date: [new Date(moment().subtract(6, 'days').format()), new Date()],
      belongType: [
        {
          value: 0,
          label: '未分类',
        }, {
          value: 1,
          label: '个人',
        }, {
          value: 2,
          label: '门店',
        },
      ],
      belongModalStores: [],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        nickName: '',
        mobile: '',
        storeId: '',
        type: '',
        hasTimeCard: null,
        memberShip: null,
      },
      customerAccounts: [],
      customConfig: {
        visible: false,
      },
      customModalData: {
        bindStoreId: '',
        selectStoreIds: [],
      },
      customModalRule: {
        mobile: [
          { required: true, message: TIPS.MOBILE, trigger: 'change' },
          { pattern: '^[0-9]*$', message: '请输入正确的手机号', trigger: 'change' },
        ],
      },
      trainRecord: [],
      editPrescriptionVisible: false,
      prescriptionsList: {},
      customId: null,
      quicklyChange: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: '最近一个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
        ],
      },
      familyMemberConfig: {
        visible: false,
        editVisible: false,
        deleteVisible: false,
      },
      familyMembers: [],
      familyMemberModalData: {},
      familyMemberModalRule: {
        nick_name: [{ required: true, trigger: 'change', message: '用户名不能为空' }],
        sex: [{ required: true, trigger: 'change', type: 'number' }],
        birthday: [{ required: true, trigger: 'change' }],
        relation: [{ required: true, trigger: 'change', type: 'number' }],
      },
      timeFreqCardConfig: {
        visible: false,
      },
      timeFreqCards: [],
      editCustomerStoreVisible: false,
      editCustomerStoreRow: {},
    };
  },
  created() {
    this.fetchBelongStores();
    this.fetchCustomerAccounts();
  },
  methods: {
    async fetchCustomerAccounts() {
      const {
        page,
        limit,
        nickName,
        storeId,
        mobile,
        type,
        hasTimeCard,
        memberShip,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await CustomerAccountManageApi.getCustomers(
        page,
        limit,
        nickName,
        storeId,
        mobile,
        type,
        hasTimeCard,
        memberShip,
      );
      this.customModalData = {
        ...this.customModalData,
        bindStoreId: storeId,
        selectStoreIds: [],
      };
      this.customerAccounts = results;
      this.$set(this.queryData, 'count', count);
    },
    async fetchBelongStores() {
      const result = await StoreManageApi.getStoresByStoreNameOrChannelId();
      this.belongStores = DataTransferUtil.originTransfer2Options(result);
    },
    async fetchBelongStoresByCustomerId(id = NaN) {
      this.belongModalStores = await CustomerAccountManageApi.getBelongStores(id);
    },
    async getRecordList(id = NaN) {
      const { startDate, endDate } = this.customModalData;
      this.trainRecord = await CustomerAccountManageApi.getRecordList(id, startDate, endDate);
    },
    onSelect(selections) {
      this.$set(this.customModalData, 'selectStoreIds', selections.map((selection) => selection.id));
    },
    async onModalConfirm() {
      const {
        id, status, nickName, mobile, storeId, selectStoreIds, bindStoreId, birthday, memberLimit,
      } = this.customModalData;

      switch (this.customConfig.action) {
        case CUS_ACTION.CREATE:
          await CustomerAccountManageApi.rebind(selectStoreIds, bindStoreId, storeId);
          break;
        case CUS_ACTION.EDIT:
          await CustomerAccountManageApi.customerEdit(id, nickName, mobile, birthday, memberLimit);
          break;
        case CUS_ACTION.TOGGLESTATUS:
          await this.onToggleStatus(id, status);
          break;
        case CUS_ACTION.PARENTSTORE:
          break;
        case CUS_ACTION.REPORT:
          break;
        default:
          break;
      }
      await this.onCustomAction(null);
      await this.fetchCustomerAccounts();
    },
    // eslint-disable-next-line consistent-return
    async onCustomAction(action = CUS_ACTION.CREATE, visible = false, sgsId = NaN, row) {
      if (
        action === CUS_ACTION.EDIT
      ) {
        this.customModalData = {
          ...this.customModalData,
          id: row.id,
          mobile: row.mobile,
          birthday: row.birthday,
          nickName: row.nick_name,
          sex: row.sex,
          status: row.status,
          createTime: row.create_time,
          memberLimit: row.member_limit,
        };
      } else if (action === CUS_ACTION.TOGGLESTATUS
      ) {
        this.customModalData = {
          ...this.customModalData,
          id: row.id,
          status: row.status,
        };
        if (!row.status) return this.onToggleStatus(row.id, row.status);
      } else if (action === CUS_ACTION.PARENTSTORE
      ) {
        await this.fetchBelongStoresByCustomerId(sgsId);
      } else if (action === CUS_ACTION.REPORT
      ) {
        this.customModalData = {
          ...this.customModalData,
          id: sgsId,

        };
        this.date = [new Date(moment().subtract(6, 'days').format()), new Date()];
        await this.getRecordList(sgsId);
      } else {
        this.customModalData = {
          ...this.customModalData,
          id: sgsId,
          status: '',
          storeId: '',
          mobile: '',
          birthday: '',
          createTime: '',
          nickName: '',
          sex: '',
        };
      }
      this.customConfig = {
        ...this.customConfig,
        visible,
        action,
      };
    },
    onChangeDate(values) {
      this.customModalData = {
        ...this.customModalData,
        startDate: values[0] || '',
        endDate: values[1] || '',
      };
    },
    async onEditPrescription(id) {
      const result = await CustomerAccountManageApi.getPrescriptions(id);
      this.prescriptionsList = {
        ...result,
        ...{ prescription: JSON.parse(result.prescription || '[]') },
      };
      this.customId = id;
      this.editPrescriptionVisible = true;
    },
    onCancel() {
      this.editPrescriptionVisible = false;
      this.prescriptionsList = {};
    },
    onViewReport(id) {
      window.open(`${window.location.origin}/#/report?id=${id}&parentId=${this.customModalData.id}`);
    },
    async onToggleStatus(id, status) {
      await CustomerAccountManageApi.toggleStatus(id, !status);
      await this.fetchCustomerAccounts();
    },
    async onExport() {
      const key = await CustomerAccountManageApi.export(this.queryData);
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async onExportAll() {
      const key = await CustomerAccountManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },

    // 家庭子账号
    async onShowMember(id) {
      this.familyMembers = await CustomerAccountManageApi.getFamilyMembers(id);
      this.familyMemberConfig.mainMemberId = id;
      this.familyMemberConfig.visible = true;
    },

    // 家庭子账号
    async onShowTimeFreqCard(id) {
      this.timeFreqCards = await CustomerAccountManageApi.getTimeFreqCards(id);
      this.timeFreqCardConfig.visible = true;
    },

    onFamilyMemberEdit(row) {
      this.familyMemberModalData = {
        ...row,
      };
      // this.familyMemberConfig.visible = false;
      this.familyMemberConfig.editVisible = true;
    },

    onFamilyMemberPrescriptionEdit(id) {
      this.onEditPrescription(id);
    },

    onFamilyMemberModalConfirm() {
      this.$refs.familyMemberForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const {
          // eslint-disable-next-line camelcase
          id, nick_name, sex, birthday, relation,
        } = this.familyMemberModalData;
        await CustomerAccountManageApi.editFamilyMember(id, nick_name, sex, birthday, relation);
        this.familyMemberConfig.editVisible = false;
        await this.onShowMember(this.familyMemberConfig.mainMemberId);
      });
    },

    onFamilyMemberDelete(row) {
      this.familyMemberModalData = {
        ...row,
      };
      this.familyMemberConfig.deleteVisible = true;
      this.familyMemberConfig.deleteNickName = row.nick_name;
    },
    // 删除子账号
    async onFamilyMemberModalDeleteConfirm() {
      const {
        // eslint-disable-next-line camelcase
        id, nick_name, sex, birthday, relation,
      } = this.familyMemberModalData;
      // 调用编辑接口,传入删除时间
      await CustomerAccountManageApi.editFamilyMember(
        id, nick_name, sex, birthday, relation, moment.now(),
      );
      this.familyMemberConfig.deleteVisible = false;
      await this.onShowMember(this.familyMemberConfig.mainMemberId);
    },

    onFamilyMemberModalCancel() {
      this.familyMemberConfig.visible = false;
      this.familyMemberConfig.editVisible = false;
    },

    // 转换年龄
    convertToAge(birthday) {
      if (!birthday) {
        return '';
      }
      return moment().diff(moment(birthday), 'years');
    },

    showEditCustomerStore(row) {
      this.editCustomerStoreVisible = true;
      this.editCustomerStoreRow = row;
    },

    converJxwVIpExpireTime(row) {
      if (!row.jxw_vip_expire_time) {
        return '否';
      }

      const expireTime = row.jxw_vip_expire_time;
      if (new Date() > new Date(expireTime)) {
        return `${expireTime}过期`;
      }

      return `有效期至${row.jxw_vip_expire_time}`;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ivu-picker-panel-sidebar {
  width: auto !important;
}
</style>
