<template>
  <div>
    <app-modal
      :value="visible"
      title="修改处方"
      width="600"
      @on-cancel="() => $emit('on-cancel')"
    >
      <Card  :padding="0" shadow style="width: 100%">
        <div class="head">
          <h4>{{getOperationTitle(prescriptionsList)}}</h4>
          <Button
            type="primary"
            ghost
            @click="onPreviewHistories">
            历史处方
          </Button>
        </div>
        <taskCard
          :prescription="prescriptionsList.prescription"
          @upClick="upClick"
          @downClick="downClick"
          @del="del"
        />
      </Card>
      <div slot="footer">
        <Button
          type="primary"
          size="large"
          @click="() => toggleCreatePrescriptionVisible(true)">
          添加训练项目
        </Button>
        <Button type="primary"
                size="large"
                @click="save"
                :disabled="!(prescriptionsList.prescription
              &&prescriptionsList.prescription.length)">保存并返回</Button>
      </div>
    </app-modal>
    <create-training-program
      :visible="createPrescriptionVisible"
      @on-ok="onCreatePrescription"
      @on-cancel="() => toggleCreatePrescriptionVisible(false)" />
    <app-modal
      footer-hide
      :value="previewHistoriesVisible"
      title="历史方案"
      @on-cancel="() => this.previewHistoriesVisible = false">
      <div style="max-height: 600px; overflow: auto">
        <Table
          :show-header="false"
          :columns="HISTORIES_COLUMNS"
          :data="histories">
          <template slot="operationTime" slot-scope="{ row }">
            {{getOperationName(row.opt_time)}}
          </template>
          <template slot="operations" slot-scope="{ row }">
            <Button
              type="primary"
              @click="() => onPreviewHistoryDetail(row.id)">
              查看方案
            </Button>
          </template>
        </Table>
      </div>
    </app-modal>
    <app-modal
      :value="previewHistoryDetailVisible"
      :title="`${getOperationName(historyDetail.opt_time)}的方案`"
      @on-cancel="() => this.previewHistoryDetailVisible = false">
      <h3 class="title">{{getOperationTitle(historyDetail)}}</h3>
      <div style="max-height: 600px; overflow: auto">
        <taskCard :action-visible="false" :prescription="historyDetail.prescription" />
      </div>
      <template slot="footer">
        <Button
          type="primary"
          @click="() => onReplaceCurrentPrescription(historyDetail.id)">
          替换当前方案
        </Button>
      </template>
    </app-modal>
  </div>
</template>

<script>
import moment from 'moment';
import taskCard from './taskCard.vue';
import CreateTrainingProgram from './create-training-program.component.vue';
import CustomerAccountManageApi from './customer-account-manage.api';

const HISTORIES_COLUMNS = [
  { key: 'opt_time', slot: 'operationTime', width: 240 },
  { key: 'operations', slot: 'operations', width: 120 },
];

export default {
  name: 'edit-prescription.component.vue',
  components: {
    taskCard,
    CreateTrainingProgram,
  },
  props: {
    visible: {
      type: Boolean,
    },
    prescriptionsList: {
      type: Object,
    },
    customId: {
      type: Number,
    },
  },
  data() {
    return {
      HISTORIES_COLUMNS,
      prescription: [],
      createPrescriptionVisible: false,
      previewHistoriesVisible: false,
      histories: [],
      previewHistoryDetailVisible: false,
      historyDetail: {},
    };
  },
  methods: {
    swapItems(arr, index1, index2) {
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    upClick(index) {
      if (!index) return;
      const newArr = this.swapItems(this.prescriptionsList.prescription, index, index - 1);
      this.$set(this.prescriptionsList, 'prescription', newArr);
    },
    // 下移
    downClick(index) {
      if (this.prescriptionsList.prescription.length === index + 1) return;
      const newArr = this.swapItems(this.prescriptionsList.prescription, index, index + 1);
      this.$set(this.prescriptionsList, 'prescription', newArr);
    },
    del(index) {
      // eslint-disable-next-line radix
      const timeLen = Number.parseInt(this.prescriptionsList.time_len) || 0;
      const newArr = [...this.prescriptionsList.prescription];
      // eslint-disable-next-line radix
      const delTimeLen = Number.parseInt(Object.values(newArr[index])[0]['训练时长']?.replace('*', '')) || 0;
      const newTimeLen = timeLen - delTimeLen;
      newArr.splice(index, 1);
      this.$set(this.prescriptionsList, 'prescription', newArr);
      this.$set(this.prescriptionsList, 'time_len', newTimeLen);
    },
    async save() {
      if (this.prescriptionsList.time_len > 60) {
        this.$Message.error('预计时长大于60分钟');
        return;
      }

      await CustomerAccountManageApi.savePrescriptions(this.customId, this.prescriptionsList);
      this.$emit('on-cancel');
    },
    toggleCreatePrescriptionVisible(visible = false) {
      this.createPrescriptionVisible = visible;
    },
    onCreatePrescription(data) {
      const { time_len: createTimeLen, prescription } = data;

      // eslint-disable-next-line radix
      const timeLen = Number.parseInt(this.prescriptionsList.time_len) || 0;
      const newArr = [...this.prescriptionsList.prescription, ...prescription];
      // eslint-disable-next-line radix
      const newTimeLen = timeLen + createTimeLen;
      this.$set(this.prescriptionsList, 'prescription', newArr);
      this.$set(this.prescriptionsList, 'time_len', newTimeLen);
      this.toggleCreatePrescriptionVisible(false);
    },
    async onPreviewHistories() {
      this.histories = await CustomerAccountManageApi.prescriptionHistories(this.customId);
      this.previewHistoriesVisible = true;
    },
    getOperationName(time) {
      return `${moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')}的方案`;
    },
    getOperationTitle(detail) {
      return `当前方案-共${detail.prescription?.length || 0}项训练，预计${detail.time_len || 0}分钟`;
    },
    async onPreviewHistoryDetail(logId) {
      this.historyDetail = await CustomerAccountManageApi.prescriptionHistoryDetail(logId);
      this.historyDetail.prescription = JSON.parse(this.historyDetail.content);
      this.previewHistoryDetailVisible = true;
    },
    async onReplaceCurrentPrescription(logId) {
      await CustomerAccountManageApi.useOldRecordReplacePrescription(logId);
      this.previewHistoriesVisible = false;
      this.previewHistoryDetailVisible = false;
      this.$emit('on-cancel');
    },
  },
};
</script>

<style scoped lang="less">
.actionBtn button {
  color: #5B8CFF;
}
/deep/ .ivu-cell-main {
  display: block;
}
/deep/ .ivu-cell-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div:nth-child(1) {
    width: 70%;
  }
}
/deep/.ivu-card-body {
  height: 60vh;
  overflow-y: auto;
}
.cardHead {
  display: flex;
  justify-content:space-between;
}
.footer {
  display: flex;
  justify-content: flex-end;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: @spacingSM;
}

.title {
  margin-bottom: @spacingSM;
}
</style>
