var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-modal',{attrs:{"value":_vm.visible,"title":"修改处方","width":"600"},on:{"on-cancel":function () { return _vm.$emit('on-cancel'); }}},[_c('Card',{staticStyle:{"width":"100%"},attrs:{"padding":0,"shadow":""}},[_c('div',{staticClass:"head"},[_c('h4',[_vm._v(_vm._s(_vm.getOperationTitle(_vm.prescriptionsList)))]),_c('Button',{attrs:{"type":"primary","ghost":""},on:{"click":_vm.onPreviewHistories}},[_vm._v(" 历史处方 ")])],1),_c('taskCard',{attrs:{"prescription":_vm.prescriptionsList.prescription},on:{"upClick":_vm.upClick,"downClick":_vm.downClick,"del":_vm.del}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary","size":"large"},on:{"click":function () { return _vm.toggleCreatePrescriptionVisible(true); }}},[_vm._v(" 添加训练项目 ")]),_c('Button',{attrs:{"type":"primary","size":"large","disabled":!(_vm.prescriptionsList.prescription
            &&_vm.prescriptionsList.prescription.length)},on:{"click":_vm.save}},[_vm._v("保存并返回")])],1)],1),_c('create-training-program',{attrs:{"visible":_vm.createPrescriptionVisible},on:{"on-ok":_vm.onCreatePrescription,"on-cancel":function () { return _vm.toggleCreatePrescriptionVisible(false); }}}),_c('app-modal',{attrs:{"footer-hide":"","value":_vm.previewHistoriesVisible,"title":"历史方案"},on:{"on-cancel":function () { return this$1.previewHistoriesVisible = false; }}},[_c('div',{staticStyle:{"max-height":"600px","overflow":"auto"}},[_c('Table',{attrs:{"show-header":false,"columns":_vm.HISTORIES_COLUMNS,"data":_vm.histories},scopedSlots:_vm._u([{key:"operationTime",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getOperationName(row.opt_time))+" ")]}},{key:"operations",fn:function(ref){
            var row = ref.row;
return [_c('Button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.onPreviewHistoryDetail(row.id); }}},[_vm._v(" 查看方案 ")])]}}])})],1)]),_c('app-modal',{attrs:{"value":_vm.previewHistoryDetailVisible,"title":((_vm.getOperationName(_vm.historyDetail.opt_time)) + "的方案")},on:{"on-cancel":function () { return this$1.previewHistoryDetailVisible = false; }}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.getOperationTitle(_vm.historyDetail)))]),_c('div',{staticStyle:{"max-height":"600px","overflow":"auto"}},[_c('taskCard',{attrs:{"action-visible":false,"prescription":_vm.historyDetail.prescription}})],1),_c('template',{slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.onReplaceCurrentPrescription(_vm.historyDetail.id); }}},[_vm._v(" 替换当前方案 ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }