<template>
  <div>
    <app-modal
      style="z-index: 1000;"
      :value="visible"
      :title="`归属门店 - ${customer.nick_name}`"
      width="800"
      :footerHide="true"
      :zIndex="1000"
      @on-cancel="onCancel"
    >
      <el-table
        :data="storeList"
        stripe
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="store_name"
          align="center"
          label="门店名称"
          minWidth="150">
        </el-table-column>
        <el-table-column
          prop="remain_freq_card"
          align="center"
          label="剩余基础卡次数"
          minWidth="120">
        </el-table-column>
        <el-table-column
          prop="remain_time_freq_card"
          align="center"
          label="剩余时效卡次数"
          minWidth="120">
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          minWidth="120">
          <template slot-scope="scope">
            <Button
              v-if="sysUserType"
              @click="() => unbindStore(scope.row)"
              type="primary">
              解绑
            </Button>
          </template>
        </el-table-column>
      </el-table>

      <div v-if="sysUserType" style="margin-top: 30px;">
        <Form
          ref="storeForm"
          :inline="true"
          :model="bindStoreData"
          :rules="bindStoreRule"
          :label-width="80">
          <FormItem
            label="分配渠道"
            prop="channelId"
          >
            <Select
              filterable
              v-model="bindStoreData.channelId"
              @on-select="(V)=>handleChangeChannel(V.value)"
            >
              <Option
                v-for="option in bindStoreData.channelOptions"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            label="分配门店"
            prop="storeId"
          >
            <Select
              filterable
              v-model="bindStoreData.storeId"
              @on-select="(V)=>handleChangeStore(V)"
            >
              <Option
                v-for="option in bindStoreData.storeOptions"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="error" @click="bindStore()">绑定</Button>
          </FormItem>
        </Form>
      </div>

      <div slot="footer">
      </div>
    </app-modal>

    <!-- 解绑 -->
    <app-modal
      :value="unbindConfig.unbindVisible"
      title="解绑门店"
      width="600"
      @on-cancel="onUnbindClear">
      <h3>
        {{`是否确认解绑用户 "${customer.nick_name}" 与门店 "${unbindConfig.store.store_name}"的关系?`}}
      </h3>
      <h3>
        {{
          `解绑后将清除该门店下剩余基础卡${unbindConfig.store.remain_freq_card}次,
          剩余时效卡${unbindConfig.store.remain_time_freq_card}次`
        }}
      </h3>
      <template slot="footer" class="dialog-footer">
        <Button style="margin-right:16px;" @click="onUnbindClear">
          取消
        </Button>
        <Button type="error" @click="onUnbindConfirm">确认解绑</Button>
      </template>
    </app-modal>

    <!-- 绑定 -->
    <app-modal
      :value="bindConfig.bindVisible"
      title="绑定门店"
      width="600"
      @on-cancel="onBindClear">
      <h3>
        {{`是否确认绑定用户 "${customer.nick_name}" 与门店 "${bindConfig.store.store_name}"的关系?`}}
      </h3>
      <template slot="footer" class="dialog-footer">
        <Button style="margin-right:16px;" @click="onBindClear">
          取消
        </Button>
        <Button type="primary" @click="onBindConfirm">确认绑定</Button>
      </template>
    </app-modal>

  </div>
</template>

<script>
import { USER_TYPE } from '../../login/constants';
import CustomerAccountManageApi from './customer-account-manage.api';
import StoreManageApi from '../../business/store-manage/store-manage.api';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';

export default {
  name: 'edit-customer-store.vue',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
    },
    customer: {
      type: Object,
    },
  },
  watch: {
    customer(newVal) {
      this.init(newVal.id);
    },
  },
  data() {
    return {
      sysUserType: this.$store.getters.userType === USER_TYPE.ADMIN,
      storeList: [],
      unbindConfig: {
        unbindVisible: false,
        store: {},
      },
      bindConfig: {
        bindVisible: false,
        store: {},
      },
      bindStoreData: {
        channelId: null,
        storeId: null,
        channelOptions: [],
        storeOptions: [],
      },
      bindStoreRule: {
        channelId: [
          {
            required: true,
            message: '请选择分配渠道',
            trigger: 'blur',
            type: 'number',
          },
        ],
        storeId: [
          {
            required: true,
            message: '请选择分配门店',
            trigger: 'blur',
            type: 'number',
          },
        ],
      },

    };
  },
  methods: {
    init(id) {
      this.fetchBelongStoresByCustomerId(id);
      this.bindConfig = {
        bindVisible: false,
        store: {},
      };
      this.unbindConfig = {
        unbindVisible: false,
        store: {},
      };
    },
    async fetchBelongStoresByCustomerId(id) {
      this.storeList = await CustomerAccountManageApi.getCustomerStoreAll(id);
      this.fetchModelChannelOptions();
    },

    unbindStore(row) {
      this.unbindConfig.store = row;
      this.unbindConfig.unbindVisible = true;
    },

    onUnbindClear() {
      this.unbindConfig.unbindVisible = false;
    },
    async onUnbindConfirm() {
      await CustomerAccountManageApi.changeCustomerStore(
        0, this.customer.id, this.unbindConfig.store.store_id,
      ).then(() => {
        this.onUnbindClear();
        this.fetchBelongStoresByCustomerId(this.customer.id);
      });
    },

    bindStore() {
      this.$refs.storeForm.validate((valid) => {
        if (valid) {
          this.bindConfig.bindVisible = true;
          return true;
        }
        return false;
      });
    },
    onBindClear() {
      this.bindConfig.store = {};
      this.bindConfig.bindVisible = false;
      this.bindStoreData.channelId = null;
      this.bindStoreData.storeId = null;
    },
    async onBindConfirm() {
      await CustomerAccountManageApi.changeCustomerStore(
        1, this.customer.id, this.bindConfig.store.id,
      );
      this.onBindClear();
      this.fetchBelongStoresByCustomerId(this.customer.id);
    },

    handleChangeChannel(channelId) {
      this.bindStoreData.storeId = null;
      this.fetchModelStoreOptions(channelId);
    },

    handleChangeStore(v) {
      this.bindConfig.store = {
        store_name: v.label,
        id: v.value,
      };
    },

    async fetchModelChannelOptions() {
      const channelOptions = await ChannelManageApi.getSubChannelOfCurrentUser();
      this.$set(this.bindStoreData, 'channelOptions', channelOptions);
    },

    async fetchModelStoreOptions(channelId) {
      const storeOptions = await StoreManageApi.getStoresByStoreNameOrChannelId('', channelId);
      this.$set(this.bindStoreData, 'storeOptions', storeOptions);
    },

    onCancel() {
      this.$emit('on-cancel');
    },

  },

};
</script>

<style scoped lang="less">

</style>
