<template>
  <div>
    <div v-for="(item, index) in prescription" :key="index">
      <div
        class="row-item"
        v-for="(val, key, ind) in item"
        :key="ind">
        <div class="left">
          <h3 class="head">
            {{key}}
          </h3>
          <div class="content">
          <template
            v-for="(v, k, i) in val">
            <span
              class="column"
              v-if="propertyVisible(k, v)"
              :key="i">
              {{convertNewHardwareTitle(key, k)}}: <i class="value">{{convertNewHardwareData(key, v)}}</i>
            </span>
          </template>
          </div>
        </div>
        <div v-if="actionVisible" class="action-buttons">
          <Button type="text" @click="()=>$emit('upClick',index)">上移</Button>
          <Button type="text" @click="()=>$emit('downClick',index)">下移</Button>
          <Button type="text" @click="()=>$emit('del',index)">删除</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'taskCard',
  props: {
    actionVisible: {
      type: Boolean,
      default: true,
    },
    prescription: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    propertyVisible(key, value) {
      return !(key === '训练时长' && value.charAt(0) === '*');
    },
    convertNewHardwareData(type, content) {
      let result = content;
      if (type === '调节幅度训练'
        || type === '调节灵活度训练'
        || type === '镜片阅读训练') {
        result = content.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
      }
      return result;
    },
    convertNewHardwareTitle(type, title) {
      let result = title;
      if (type === '镜片阅读训练' && title === '初始训练难度') {
        result = '初始难度等级';
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.row-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: @spacingSM 0;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
}
.left {
  flex: 1 1 auto;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .column {
    display: inline-block;
    width: 50%;
    .value {
      font-style: normal;
      color: @mainBlue;
    }
  }
}
.action-buttons {
  flex: 0 0 auto;
}
</style>
