var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-modal',{attrs:{"value":_vm.visible,"title":"添加处方","width":1024},on:{"on-ok":function () { return _vm.$emit('on-ok'); },"on-cancel":function () { return _vm.$emit('on-cancel'); }}},[_c('Form',{ref:"form"},[_c('FormItem',{attrs:{"label":"训练次数"}},[_c('InputNumber',{attrs:{"controls-outside":"","min":1},model:{value:(_vm.repeatTime),callback:function ($$v) {_vm.repeatTime=$$v},expression:"repeatTime"}})],1),_c('FormItem',{attrs:{"label":"请选择训练类型"}},[_c('el-radio-group',{attrs:{"type":"button","button-style":"solid"},on:{"change":function (k) { return _vm.setPrimaryLevelValue(k, _vm.CONSTANTS.primaryClassificationText[k]); }},model:{value:(_vm.primaryClassification),callback:function ($$v) {_vm.primaryClassification=$$v},expression:"primaryClassification"}},_vm._l((_vm.CONSTANTS.primaryClassificationText),function(v,k){return _c('el-radio-button',{key:k,attrs:{"label":k}},[_vm._v(" "+_vm._s(v)+" ")])}),1)],1),(_vm.secondaryClassificationConstantName)?_c('FormItem',{attrs:{"label":"请选择训练项目"}},[_c('el-radio-group',{attrs:{"type":"button","button-style":"solid"},on:{"change":function (k) { return _vm.setSecondLevelValue(
          k,
           _vm.CONSTANTS[_vm.secondaryClassificationConstantName][k]
        ); }},model:{value:(_vm.secondaryClassification),callback:function ($$v) {_vm.secondaryClassification=$$v},expression:"secondaryClassification"}},_vm._l((_vm.CONSTANTS[_vm.secondaryClassificationConstantName]),function(v,k){return _c('el-radio-button',{key:k,attrs:{"label":k}},[_vm._v(" "+_vm._s(v)+" ")])}),1)],1):_vm._e(),_vm._l((_vm.CONSTANTS[_vm.secondarySubTitleConstantNames]),function(v,k){return _c('FormItem',{key:k,attrs:{"label":v}},[(_vm.secondLevelIsMaxSingleSelection)?void 0:_vm._e(),(_vm.secondLevelIsMaxSingleSelection)?_c('el-radio-group',{attrs:{"type":"button","button-style":"solid"},on:{"change":function (sv) { return _vm.setThreeLevelDynamicValueByDynamicName(v, sv); }},model:{value:(_vm.threeLevelDynamicValueObject[v]),callback:function ($$v) {_vm.$set(_vm.threeLevelDynamicValueObject, v, $$v)},expression:"threeLevelDynamicValueObject[v]"}},_vm._l((_vm.threeLevelDynamicOptionsCache[k]),function(sv,sk){return _c('el-radio-button',{key:sk,attrs:{"label":sv}},[_vm._v(" "+_vm._s(sv)+" ")])}),1):_c('el-radio-group',{attrs:{"type":"button","button-style":"solid"},on:{"change":function (sk) { return _vm.setThreeLevelValue(sk, _vm.threeLevelDynamicOptionsCache[k][sk], v); }},model:{value:(_vm.threeLevelClassification),callback:function ($$v) {_vm.threeLevelClassification=$$v},expression:"threeLevelClassification"}},_vm._l((_vm.threeLevelDynamicOptionsCache[k]),function(sv,sk){return _c('el-radio-button',{key:sk,attrs:{"label":sk}},[_vm._v(" "+_vm._s(sv)+" ")])}),1)],2)}),_vm._l((_vm.CONSTANTS[_vm.threeLevelSubTitleConstantNames]),function(v,k){return _c('FormItem',{key:k,attrs:{"label":_vm.convertNewHardwareTitle(v)}},[_c('el-radio-group',{class:_vm.chooseOneMore && (!!_vm.fourLevelDynamicValueObject[v] ? '' : 'look-like-disable'),attrs:{"type":"button","button-style":"solid"},on:{"change":function (cv) { return _vm.setFourLevelDynamicValueByDynamicName(
          v,
          cv,
          k,
          _vm.CONSTANTS[_vm.threeLevelSubTitleConstantNames]
        ); }},model:{value:(_vm.fourLevelDynamicValueObject[v]),callback:function ($$v) {_vm.$set(_vm.fourLevelDynamicValueObject, v, $$v)},expression:"fourLevelDynamicValueObject[v]"}},_vm._l((_vm.fourLevelDynamicOptionsCache[k]),function(sv,sk){return _c('el-radio-button',{key:sk,attrs:{"label":sv}},[_vm._v(" "+_vm._s(_vm.convertNewHardwareData(sv))+" ")])}),1)],1)})],2),_c('template',{slot:"footer"},[_c('Button',{attrs:{"type":"primary","disabled":_vm.saveButtonIsDisable},on:{"click":_vm.onOk}},[_vm._v(" 确认 ")]),_c('Button',{on:{"click":function () { return _vm.$emit('on-cancel'); }}},[_vm._v(" 取消 ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }