/* eslint-disable max-len */

/* 一级分类 开始 */

const primaryClassification = {
  conditioningTraining: 'conditioningTraining',
  fusionTraining: 'fusionTraining',
  amblyopiaTraining: 'amblyopiaTraining',
  fixationTraining: 'fixationTraining',
};

const primaryClassificationText = {
  [primaryClassification.conditioningTraining]: '调节训练',
  [primaryClassification.fusionTraining]: '融合训练',
  [primaryClassification.amblyopiaTraining]: '弱视训练',
  [primaryClassification.fixationTraining]: '注视功能训练',
};

/* 一级分类 结束 */

/* 二级分类 开始 */

const conditioningTrainingSecondaryClassification = {
  amplitudeAdjustmentTraining: 'amplitudeAdjustmentTraining',
  conditioningFlexibilityTraining: 'conditioningFlexibilityTraining',
  lensReadingTraining: 'lensReadingTraining',
};

const conditioningTrainingSecondaryClassificationText = {
  [conditioningTrainingSecondaryClassification.amplitudeAdjustmentTraining]: '调节幅度训练',
  [conditioningTrainingSecondaryClassification.conditioningFlexibilityTraining]: '调节灵活度训练',
  [conditioningTrainingSecondaryClassification.lensReadingTraining]: '镜片阅读训练',
};

const fusionTrainingSecondaryClassification = {
  staticImageFusionTraining: 'staticImageFusionTraining',
  dynamicImageFusionTraining: 'dynamicImageFusionTraining',
  comprehensiveImageFusionTraining: 'comprehensiveImageFusionTraining',
};

const fusionTrainingSecondaryClassificationText = {
  [fusionTrainingSecondaryClassification.staticImageFusionTraining]: '静态融像训练',
  [fusionTrainingSecondaryClassification.dynamicImageFusionTraining]: '动态融像训练',
  [fusionTrainingSecondaryClassification.comprehensiveImageFusionTraining]: '融像训练(综合)',
};

const amblyopiaTrainingSecondaryClassification = {
  disinhibitionTraining: 'disinhibitionTraining',
  redFlashTraining: 'redFlashTraining',
  gratingTraining: 'gratingTraining',
  fineVisionTraining: 'fineVisionTraining',
};

const amblyopiaTrainingSecondaryClassificationText = {
  [amblyopiaTrainingSecondaryClassification.disinhibitionTraining]: '脱抑制训练',
  [amblyopiaTrainingSecondaryClassification.redFlashTraining]: '红闪训练',
  [amblyopiaTrainingSecondaryClassification.gratingTraining]: '光栅训练',
  [amblyopiaTrainingSecondaryClassification.fineVisionTraining]: '精细目力训练',
};

const fixationTrainingSecondaryClassification = {
  eyeMuscleTraining: 'eyeMuscleTraining',
  saccadeTraining: 'saccadeTraining',
  visualFocusTraining: 'visualFocusTraining',
};

const fixationTrainingSecondaryClassificationText = {
  [fixationTrainingSecondaryClassification.eyeMuscleTraining]: '眼肌运动训练',
  [fixationTrainingSecondaryClassification.saccadeTraining]: '扫视训练',
  [fixationTrainingSecondaryClassification.visualFocusTraining]: '视觉专注训练',
};

/* 二级分类 结束 */

/* 二级标题 开始 */

const amplitudeAdjustmentTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const amplitudeAdjustmentTrainingSecondarySubTitleText = {
  [amplitudeAdjustmentTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const conditioningFlexibilityTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const conditioningFlexibilityTrainingSecondarySubTitleText = {
  [conditioningFlexibilityTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const lensReadingTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const lensReadingTrainingSecondarySubTitleText = {
  [lensReadingTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const staticImageFusionTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const staticImageFusionTrainingSecondarySubTitleText = {
  [staticImageFusionTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const dynamicImageFusionTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const dynamicImageFusionTrainingSecondarySubTitleText = {
  [dynamicImageFusionTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const disinhibitionTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const disinhibitionTrainingSecondarySubTitleText = {
  [disinhibitionTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const redFlashTrainingSecondarySubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
  temporalFrequency: 'temporalFrequency',
  trainingMode: 'trainingMode',
};

const redFlashTrainingSecondarySubTitleText = {
  [redFlashTrainingSecondarySubTitle.trainingEye]: '训练眼别',
  [redFlashTrainingSecondarySubTitle.trainingDuration]: '训练时长',
  [redFlashTrainingSecondarySubTitle.temporalFrequency]: '闪动频率',
  [redFlashTrainingSecondarySubTitle.trainingMode]: '训练模式',
};

const gratingTrainingSecondarySubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
  spatialFrequency: 'spatialFrequency',
  trainingMode: 'trainingMode',
};

const gratingTrainingSecondarySubTitleText = {
  [gratingTrainingSecondarySubTitle.trainingEye]: '训练眼别',
  [gratingTrainingSecondarySubTitle.trainingDuration]: '训练时长',
  [gratingTrainingSecondarySubTitle.spatialFrequency]: '空间频率',
  [gratingTrainingSecondarySubTitle.trainingMode]: '训练模式',
};

const fineVisionTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const fineVisionTrainingSecondarySubTitleText = {
  [fineVisionTrainingSecondarySubTitle.trainingType]: '训练类型',
};

const eyeMuscleTrainingSecondarySubTitle = {
  motionMode: 'motionMode',
  motionSpeed: 'motionSpeed',
};

const eyeMuscleTrainingSecondarySubTitleText = {
  [eyeMuscleTrainingSecondarySubTitle.motionMode]: '运动模式',
  [eyeMuscleTrainingSecondarySubTitle.motionSpeed]: '初始运动速度',
};

const saccadeTrainingSecondarySubTitle = {
  fontSize: 'fontSize',
  trainingProgram: 'trainingProgram',
};

const saccadeTrainingSecondarySubTitleText = {
  [saccadeTrainingSecondarySubTitle.fontSize]: '字体大小',
  [saccadeTrainingSecondarySubTitle.trainingProgram]: '训练项目',
};

const visualFocusTrainingSecondarySubTitle = {
  trainingType: 'trainingType',
};

const visualFocusTrainingSecondarySubTitleText = {
  [visualFocusTrainingSecondarySubTitle.trainingType]: '训练类型',
};

/* 二级标题 结束 */

/* 三级分类 开始 */

const amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassification = {
  normal: 'normal',
  comprehensive: 'comprehensive',
  scene: 'scene',
};

const amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassificationText = {
  [amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassification.normal]: '常规',
  [amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassification.comprehensive]: '综合',
  [amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassification.scene]: '情景',
};

const conditioningFlexibilityTrainingTrainingTypeThreeLevelClassification = {
  normal: 'normal',
  comprehensive: 'comprehensive',
  scene: 'scene',
};

const conditioningFlexibilityTrainingTrainingTypeThreeLevelClassificationText = {
  [conditioningFlexibilityTrainingTrainingTypeThreeLevelClassification.normal]: '常规',
  [conditioningFlexibilityTrainingTrainingTypeThreeLevelClassification.comprehensive]: '综合',
  [conditioningFlexibilityTrainingTrainingTypeThreeLevelClassification.scene]: '情景',
};

const lensReadingTrainingTrainingTypeThreeLevelClassification = {
  normal: 'normal',
  comprehensive: 'comprehensive',
};

const lensReadingTrainingTrainingTypeThreeLevelClassificationText = {
  [lensReadingTrainingTrainingTypeThreeLevelClassification.normal]: '常规',
  [lensReadingTrainingTrainingTypeThreeLevelClassification.comprehensive]: '综合',
};

const staticImageFusionTrainingTrainingTypeThreeLevelClassification = {
  collectiveTraining: 'collectiveTraining',
  spreadOutTraining: 'spreadOutTraining',
};

const staticImageFusionTrainingTrainingTypeThreeLevelClassificationText = {
  [staticImageFusionTrainingTrainingTypeThreeLevelClassification.collectiveTraining]: '集合训练',
  [staticImageFusionTrainingTrainingTypeThreeLevelClassification.spreadOutTraining]: '散开训练',
};

const dynamicImageFusionTrainingTrainingTypeThreeLevelClassification = {
  collectiveTraining: 'collectiveTraining',
  spreadOutTraining: 'spreadOutTraining',
};

const dynamicImageFusionTrainingTrainingTypeThreeLevelClassificationText = {
  [dynamicImageFusionTrainingTrainingTypeThreeLevelClassification.collectiveTraining]: '集合训练',
  [dynamicImageFusionTrainingTrainingTypeThreeLevelClassification.spreadOutTraining]: '散开训练',
};

const disinhibitionTrainingTrainingTypeThreeLevelClassification = {
  redAndGreenReadingUnits: 'redAndGreenReadingUnits',
  captureTraining: 'captureTraining',
};

const disinhibitionTrainingTrainingTypeThreeLevelClassificationText = {
  [disinhibitionTrainingTrainingTypeThreeLevelClassification.redAndGreenReadingUnits]: '红绿阅读单位',
  [disinhibitionTrainingTrainingTypeThreeLevelClassification.captureTraining]: '捕捉训练',
};

const redFlashTrainingTrainingEyeThreeLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const redFlashTrainingTrainingEyeThreeLevelClassificationText = {
  [redFlashTrainingTrainingEyeThreeLevelClassification.leftEye]: '左眼',
  [redFlashTrainingTrainingEyeThreeLevelClassification.rightEye]: '右眼',
  [redFlashTrainingTrainingEyeThreeLevelClassification.bothEyes]: '双眼',
};

const redFlashTrainingTrainingDurationThreeLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const redFlashTrainingTrainingDurationThreeLevelClassificationText = {
  [redFlashTrainingTrainingDurationThreeLevelClassification.threeMin]: '3min',
  [redFlashTrainingTrainingDurationThreeLevelClassification.fiveMin]: '5min',
};

const redFlashTrainingTemporalFrequencyThreeLevelClassification = {
  lowFrequency: 'lowFrequency',
  middleFrequency: 'middleFrequency',
  highFrequency: 'highFrequency',
};

const redFlashTrainingTemporalFrequencyThreeLevelClassificationText = {
  [redFlashTrainingTemporalFrequencyThreeLevelClassification.lowFrequency]: '低频',
  [redFlashTrainingTemporalFrequencyThreeLevelClassification.middleFrequency]: '中频',
  [redFlashTrainingTemporalFrequencyThreeLevelClassification.highFrequency]: '高频',
};

const redFlashTrainingTrainingModeThreeLevelClassification = {
  visualBeaconMode: 'visualBeaconMode',
  findDifferent: 'findDifferent',
  comicStory: 'comicStory',
};

const redFlashTrainingTrainingModeThreeLevelClassificationText = {
  [redFlashTrainingTrainingModeThreeLevelClassification.visualBeaconMode]: '视标模式',
  [redFlashTrainingTrainingModeThreeLevelClassification.findDifferent]: '找不同',
  [redFlashTrainingTrainingModeThreeLevelClassification.comicStory]: '漫画故事',
};

const gratingTrainingTrainingEyeThreeLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const gratingTrainingTrainingEyeThreeLevelClassificationText = {
  [gratingTrainingTrainingEyeThreeLevelClassification.leftEye]: '左眼',
  [gratingTrainingTrainingEyeThreeLevelClassification.rightEye]: '右眼',
  [gratingTrainingTrainingEyeThreeLevelClassification.bothEyes]: '双眼',
};

const gratingTrainingTrainingDurationThreeLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const gratingTrainingTrainingDurationThreeLevelClassificationText = {
  [gratingTrainingTrainingDurationThreeLevelClassification.threeMin]: '3min',
  [gratingTrainingTrainingDurationThreeLevelClassification.fiveMin]: '5min',
};

const gratingTrainingSpatialFrequencyThreeLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
  fourLevel: 'fourLevel',
};

const gratingTrainingSpatialFrequencyThreeLevelClassificationText = {
  [gratingTrainingSpatialFrequencyThreeLevelClassification.firstLevel]: '1级',
  [gratingTrainingSpatialFrequencyThreeLevelClassification.secondLevel]: '2级',
  [gratingTrainingSpatialFrequencyThreeLevelClassification.threeLevel]: '3级',
  [gratingTrainingSpatialFrequencyThreeLevelClassification.fourLevel]: '4级',
};

const gratingTrainingTrainingModeThreeLevelClassification = {
  visualBeaconMode: 'visualBeaconMode',
  wordMode: 'wordMode',
  colorMapMode: 'colorMapMode',
};

const gratingTrainingTrainingModeThreeLevelClassificationText = {
  [gratingTrainingTrainingModeThreeLevelClassification.visualBeaconMode]: '视标模式',
  [gratingTrainingTrainingModeThreeLevelClassification.wordMode]: '单词模式',
  [gratingTrainingTrainingModeThreeLevelClassification.colorMapMode]: '色图模式',
};

const fineVisionTrainingTrainingTypeThreeLevelClassification = {
  fishingTraining: 'fishingTraining',
  beadingTraining: 'beadingTraining',
};

const fineVisionTrainingTrainingTypeThreeLevelClassificationText = {
  [fineVisionTrainingTrainingTypeThreeLevelClassification.fishingTraining]: '捕鱼训练',
  [fineVisionTrainingTrainingTypeThreeLevelClassification.beadingTraining]: '串珠训练',
};

const eyeMuscleTrainingMotionModeThreeLevelClassification = {
  linearMotion: 'linearMotion',
  curvilinearMotion: 'curvilinearMotion',
  randomMotion: 'randomMotion',
};

const eyeMuscleTrainingMotionModeThreeLevelClassificationText = {
  [eyeMuscleTrainingMotionModeThreeLevelClassification.linearMotion]: '直线运动',
  [eyeMuscleTrainingMotionModeThreeLevelClassification.curvilinearMotion]: '曲线运动',
  [eyeMuscleTrainingMotionModeThreeLevelClassification.randomMotion]: '随机运动',
};

const eyeMuscleTrainingMotionSpeedThreeLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
  fourLevel: 'fourLevel',
};

const eyeMuscleTrainingMotionSpeedThreeLevelClassificationText = {
  [eyeMuscleTrainingMotionSpeedThreeLevelClassification.firstLevel]: '1级',
  [eyeMuscleTrainingMotionSpeedThreeLevelClassification.secondLevel]: '2级',
  [eyeMuscleTrainingMotionSpeedThreeLevelClassification.threeLevel]: '3级',
  [eyeMuscleTrainingMotionSpeedThreeLevelClassification.fourLevel]: '4级',
};

const saccadeTrainingFontSizeThreeLevelClassification = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

const saccadeTrainingFontSizeThreeLevelClassificationText = {
  [saccadeTrainingFontSizeThreeLevelClassification.small]: '小',
  [saccadeTrainingFontSizeThreeLevelClassification.middle]: '中',
  [saccadeTrainingFontSizeThreeLevelClassification.large]: '大',
};

const saccadeTrainingTrainingProgramThreeLevelClassification = {
  visualMarkScanningTraining: 'VisualMarkScanningTraining',
  readingSpeedTraining: 'readingSpeedTraining',
  articleReadingTraining: 'articleReadingTraining',
};

const saccadeTrainingTrainingProgramThreeLevelClassificationText = {
  [saccadeTrainingTrainingProgramThreeLevelClassification.visualMarkScanningTraining]: '视标扫视训练',
  [saccadeTrainingTrainingProgramThreeLevelClassification.readingSpeedTraining]: '阅读速度训练',
  [saccadeTrainingTrainingProgramThreeLevelClassification.articleReadingTraining]: '文章阅读训练',
};

const visualFocusTrainingTrainingTypeThreeLevelClassification = {
  trackingTraining: 'trackingTraining',
  contrastReading: 'contrastReading',
  findDifferent: 'findDifferent',
};

const visualFocusTrainingTrainingTypeThreeLevelClassificationText = {
  [visualFocusTrainingTrainingTypeThreeLevelClassification.trackingTraining]: '追随训练',
  [visualFocusTrainingTrainingTypeThreeLevelClassification.contrastReading]: '反差阅读',
  [visualFocusTrainingTrainingTypeThreeLevelClassification.findDifferent]: '找不同',
};

/* 三级分类 结束 */

/* 三级标题 开始 */

const amplitudeAdjustmentTrainingNormalThreeSubTitle = {
  trainingEye: 'trainingEye',
  visualMarkLevel: 'visualMarkLevel',
  trainingDuration: 'trainingDuration',
  initialTrainingDifficulty: 'initialTrainingDifficulty',
};

const amplitudeAdjustmentTrainingNormalThreeSubTitleText = {
  [amplitudeAdjustmentTrainingNormalThreeSubTitle.trainingEye]: '训练眼别',
  [amplitudeAdjustmentTrainingNormalThreeSubTitle.visualMarkLevel]: '视标等级',
  [amplitudeAdjustmentTrainingNormalThreeSubTitle.trainingDuration]: '训练时长',
  [amplitudeAdjustmentTrainingNormalThreeSubTitle.initialTrainingDifficulty]: '初始训练难度',
};

const amplitudeAdjustmentTrainingComprehensiveThreeSubTitle = {
  trainingEye: 'trainingEye',
};

const amplitudeAdjustmentTrainingComprehensiveThreeSubTitleText = {
  [amplitudeAdjustmentTrainingComprehensiveThreeSubTitle.trainingEye]: '训练眼别',
};

const amplitudeAdjustmentTrainingSceneThreeSubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
};

const amplitudeAdjustmentTrainingSceneThreeSubTitleText = {
  [amplitudeAdjustmentTrainingSceneThreeSubTitle.trainingEye]: '训练眼别',
  [amplitudeAdjustmentTrainingSceneThreeSubTitle.trainingDuration]: '训练时长',
};

const conditioningFlexibilityTrainingNormalThreeSubTitle = {
  trainingEye: 'trainingEye',
  visualMarkLevel: 'visualMarkLevel',
  trainingDuration: 'trainingDuration',
  initialTrainingDifficulty: 'initialTrainingDifficulty',
  positiveOrNegativeLensReinforcement: 'positiveOrNegativeLensReinforcement',
};

const conditioningFlexibilityTrainingNormalThreeSubTitleText = {
  [conditioningFlexibilityTrainingNormalThreeSubTitle.trainingEye]: '训练眼别',
  [conditioningFlexibilityTrainingNormalThreeSubTitle.visualMarkLevel]: '视标等级',
  [conditioningFlexibilityTrainingNormalThreeSubTitle.trainingDuration]: '训练时长',
  [conditioningFlexibilityTrainingNormalThreeSubTitle.initialTrainingDifficulty]: '初始训练难度',
  [conditioningFlexibilityTrainingNormalThreeSubTitle.positiveOrNegativeLensReinforcement]: '正负镜加强',
};

const conditioningFlexibilityTrainingComprehensiveThreeSubTitle = {
  trainingEye: 'trainingEye',
};

const conditioningFlexibilityTrainingComprehensiveThreeSubTitleText = {
  [conditioningFlexibilityTrainingComprehensiveThreeSubTitle.trainingEye]: '训练眼别',
};

const conditioningFlexibilityTrainingSceneThreeSubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
};

const conditioningFlexibilityTrainingSceneThreeSubTitleText = {
  [conditioningFlexibilityTrainingSceneThreeSubTitle.trainingEye]: '训练眼别',
  [conditioningFlexibilityTrainingSceneThreeSubTitle.trainingDuration]: '训练时长',
};

const lensReadingTrainingNormalThreeSubTitle = {
  lensType: 'lensType',
  trainingEye: 'trainingEye',
  visualMarkLevel: 'visualMarkLevel',
  trainingDuration: 'trainingDuration',
  initialTrainingDifficulty: 'initialTrainingDifficulty',
};

const lensReadingTrainingNormalThreeSubTitleText = {
  [lensReadingTrainingNormalThreeSubTitle.lensType]: '镜片类型',
  [lensReadingTrainingNormalThreeSubTitle.trainingEye]: '训练眼别',
  [lensReadingTrainingNormalThreeSubTitle.visualMarkLevel]: '视标等级',
  [lensReadingTrainingNormalThreeSubTitle.trainingDuration]: '训练时长',
  [lensReadingTrainingNormalThreeSubTitle.initialTrainingDifficulty]: '初始训练难度',
};

const lensReadingTrainingComprehensiveThreeSubTitle = {
  lensType: 'lensType',
  trainingEye: 'trainingEye',
};

const lensReadingTrainingComprehensiveThreeSubTitleText = {
  [lensReadingTrainingComprehensiveThreeSubTitle.lensType]: '镜片类型',
  [lensReadingTrainingComprehensiveThreeSubTitle.trainingEye]: '训练眼别',
};

const dynamicImageFusionTrainingCollectiveTrainingThreeSubTitle = {
  moveLevel: 'moveLevel',
};

const dynamicImageFusionTrainingCollectiveTrainingThreeSubTitleText = {
  [dynamicImageFusionTrainingCollectiveTrainingThreeSubTitle.moveLevel]: '移动等级',
};

const dynamicImageFusionTrainingSpreadOutTrainingThreeSubTitle = {
  moveLevel: 'moveLevel',
};

const dynamicImageFusionTrainingSpreadOutTrainingThreeSubTitleText = {
  [dynamicImageFusionTrainingSpreadOutTrainingThreeSubTitle.moveLevel]: '移动等级',
};

const disinhibitionTrainingRedAndGreenReadingUnitsThreeSubTitle = {
  initialFontSizeLevel: 'initialFontSizeLevel',
};

const disinhibitionTrainingRedAndGreenReadingUnitsThreeSubTitleText = {
  [disinhibitionTrainingRedAndGreenReadingUnitsThreeSubTitle.initialFontSizeLevel]: '初始字体大小等级',
};

const disinhibitionTrainingCaptureTrainingThreeSubTitle = {
  initialSize: 'initialSize',
  butterflyMovementMode: 'butterflyMovementMode',
  butterflyMovementSpeed: 'butterflyMovementSpeed',
  netPocketColor: 'netPocketColor',
};

const disinhibitionTrainingCaptureTrainingThreeSubTitleText = {
  [disinhibitionTrainingCaptureTrainingThreeSubTitle.initialSize]: '初始大小',
  [disinhibitionTrainingCaptureTrainingThreeSubTitle.butterflyMovementMode]: '蝴蝶移动方式',
  [disinhibitionTrainingCaptureTrainingThreeSubTitle.butterflyMovementSpeed]: '蝴蝶移动速度',
  [disinhibitionTrainingCaptureTrainingThreeSubTitle.netPocketColor]: '网兜颜色',
};

const fineVisionTrainingFishingTrainingThreeSubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
  initialShoalSize: 'initialShoalSize',
  initialShoalPopulation: 'initialShoalPopulation',
  fishingMethods: 'fishingMethods',
  shoalStatus: 'shoalStatus',
};

const fineVisionTrainingFishingTrainingThreeSubTitleText = {
  [fineVisionTrainingFishingTrainingThreeSubTitle.trainingEye]: '训练眼别',
  [fineVisionTrainingFishingTrainingThreeSubTitle.trainingDuration]: '训练时长',
  [fineVisionTrainingFishingTrainingThreeSubTitle.initialShoalSize]: '初始鱼群大小',
  [fineVisionTrainingFishingTrainingThreeSubTitle.initialShoalPopulation]: '初始鱼群数量',
  [fineVisionTrainingFishingTrainingThreeSubTitle.fishingMethods]: '捕捉方式',
  [fineVisionTrainingFishingTrainingThreeSubTitle.shoalStatus]: '鱼群状态',
};

const fineVisionTrainingBeadingTrainingThreeSubTitle = {
  trainingEye: 'trainingEye',
  trainingDuration: 'trainingDuration',
  initialBeadedSize: 'initialBeadedSize',
  beadedPopulation: 'beadedPopulation',
  needleDiameter: 'needleDiameter',
};

const fineVisionTrainingBeadingTrainingThreeSubTitleText = {
  [fineVisionTrainingBeadingTrainingThreeSubTitle.trainingEye]: '训练眼别',
  [fineVisionTrainingBeadingTrainingThreeSubTitle.trainingDuration]: '训练时长',
  [fineVisionTrainingBeadingTrainingThreeSubTitle.initialBeadedSize]: '初始串珠大小',
  [fineVisionTrainingBeadingTrainingThreeSubTitle.beadedPopulation]: '串珠数量',
  [fineVisionTrainingBeadingTrainingThreeSubTitle.needleDiameter]: '针线直径',
};

const visualFocusTrainingTrackingTrainingThreeSubTitle = {
  guideTrackingTraining: 'guideTrackingTraining',
  autonomyTrackingTraining: 'autonomyTrackingTraining',
};

const visualFocusTrainingTrackingTrainingThreeSubTitleText = {
  [visualFocusTrainingTrackingTrainingThreeSubTitle.guideTrackingTraining]: '引导追随训练',
  [visualFocusTrainingTrackingTrainingThreeSubTitle.autonomyTrackingTraining]: '自主追随训练',
};

/* 三级标题 结束 */

/* 四级分类 开始 */

const amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification = {
  twentyOrThirty: 'twentyOrThirty',
  twentyOrForty: 'twentyOrForty',
  twentyOrFifty: 'twentyOrFifty',
};

const amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrThirty]: '20_30',
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrForty]: '20_40',
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrFifty]: '20_50',
};

const amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassification = {
  twoMin: 'twoMin',
  threeMin: 'threeMin',
};

const amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassification.twoMin]: '2min',
  [amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassification.threeMin]: '3min',
};

const amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification = {
  positive2Dot50: 'positive2Dot50',
  positive1Dot50: 'positive1Dot50',
  zero: 'zero',
  negative1Dot50: 'negative1Dot50',
  negative2Dot50: 'negative2Dot50',
  negative3Dot50: 'negative3Dot50',
  negative4Dot50: 'negative4Dot50',
  negative5Dot50: 'negative5Dot50',
};

const amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.positive2Dot50]: '+2.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.positive1Dot50]: '+1.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.zero]: '0',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.negative1Dot50]: '-1.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.negative2Dot50]: '-2.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.negative3Dot50]: '-3.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.negative4Dot50]: '-4.50',
  [amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification.negative5Dot50]: '-5.50',
};

const amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassification.threeMin]: '3min',
  [amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassification.fiveMin]: '5min',
};

const conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassificationText = {
  [conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const conditioningFlexibilityTrainingNormalVisualMarkLevelFourLevelClassification = {
  twentyOrThirty: 'twentyOrThirty',
  twentyOrForty: 'twentyOrForty',
  twentyOrFifty: 'twentyOrFifty',
};

const conditioningFlexibilityTrainingNormalVisualMarkLevelFourLevelClassificationText = {
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrThirty]: '20_30',
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrForty]: '20_40',
  [amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrFifty]: '20_50',
};

const conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassification = {
  twoMin: 'twoMin',
  threeMin: 'threeMin',
};

const conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassificationText = {
  [conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassification.twoMin]: '2min',
  [conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassification.threeMin]: '3min',
};

const conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassification = {
  positiveOrNegative1Dot50: 'positiveOrNegative1Dot50',
  positiveOrNegative2Dot50: 'positiveOrNegative2Dot50',
};

const conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassificationText = {
  [conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassification.positiveOrNegative1Dot50]: '±1.50',
  [conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassification.positiveOrNegative2Dot50]: '±2.50',
};

const conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassification = {
  positiveReinforcement: 'positiveReinforcement',
  negativeReinforcement: 'negativeReinforcement',
  notReinforcement: 'notReinforcement',
};

const conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassificationText = {
  [conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassification.positiveReinforcement]: '正镜加强',
  [conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassification.negativeReinforcement]: '负镜加强',
  [conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassification.notReinforcement]: '不加强',
};

const conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassificationText = {
  [conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassificationText = {
  [conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassificationText = {
  [conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassification.threeMin]: '3min',
  [conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassification.fiveMin]: '5min',
};

const lensReadingTrainingNormalLensTypeFourLevelClassification = {
  positiveReading: 'positiveReinforcement',
  negativeReading: 'negativeReinforcement',
};

const lensReadingTrainingNormalLensTypeFourLevelClassificationText = {
  [lensReadingTrainingNormalLensTypeFourLevelClassification.positiveReading]: '正片阅读',
  [lensReadingTrainingNormalLensTypeFourLevelClassification.negativeReading]: '负片阅读',
};

const lensReadingTrainingNormalTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const lensReadingTrainingNormalTrainingEyeFourLevelClassificationText = {
  [lensReadingTrainingNormalTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [lensReadingTrainingNormalTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [lensReadingTrainingNormalTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const lensReadingTrainingNormalVisualMarkLevelFourLevelClassification = {
  twentyOrThirty: 'twentyOrThirty',
  twentyOrForty: 'twentyOrForty',
  twentyOrFifty: 'twentyOrFifty',
};

const lensReadingTrainingNormalVisualMarkLevelFourLevelClassificationText = {
  [lensReadingTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrThirty]: '20_30',
  [lensReadingTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrForty]: '20_40',
  [lensReadingTrainingNormalVisualMarkLevelFourLevelClassification.twentyOrFifty]: '20_50',
};

const lensReadingTrainingNormalTrainingDurationFourLevelClassification = {
  twoMin: 'twoMin',
  threeMin: 'threeMin',
};

const lensReadingTrainingNormalTrainingDurationFourLevelClassificationText = {
  [lensReadingTrainingNormalTrainingDurationFourLevelClassification.twoMin]: '2min',
  [lensReadingTrainingNormalTrainingDurationFourLevelClassification.threeMin]: '3min',
};

const lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassification = {
  positiveOrNegative1Dot50: 'positiveOrNegative1Dot50',
  positiveOrNegative2Dot50: 'positiveOrNegative2Dot50',
};

const lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassificationText = {
  [lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassification.positiveOrNegative1Dot50]: '1.50',
  [lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassification.positiveOrNegative2Dot50]: '2.50',
};

const lensReadingTrainingComprehensiveLensTypeFourLevelClassification = {
  positiveReading: 'positiveReinforcement',
  negativeReading: 'negativeReinforcement',
};

const lensReadingTrainingComprehensiveLensTypeFourLevelClassificationText = {
  [lensReadingTrainingComprehensiveLensTypeFourLevelClassification.positiveReading]: '正片阅读',
  [lensReadingTrainingComprehensiveLensTypeFourLevelClassification.negativeReading]: '负片阅读',
};

const lensReadingTrainingComprehensiveTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const lensReadingTrainingComprehensiveTrainingEyeFourLevelClassificationText = {
  [lensReadingTrainingComprehensiveTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [lensReadingTrainingComprehensiveTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [lensReadingTrainingComprehensiveTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
};

const dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassificationText = {
  [dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassification.firstLevel]: '1级',
  [dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassification.secondLevel]: '2级',
  [dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassification.threeLevel]: '3级',
};

const dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
};

const dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassificationText = {
  [dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassification.firstLevel]: '1级',
  [dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassification.secondLevel]: '2级',
  [dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassification.threeLevel]: '3级',
};

const disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
  fourLevel: 'fourLevel',
  fiveLevel: 'fiveLevel',
  sixLevel: 'sixLevel',
};

const disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassificationText = {
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.firstLevel]: '1级',
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.secondLevel]: '2级',
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.threeLevel]: '3级',
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.fourLevel]: '4级',
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.fiveLevel]: '5级',
  [disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification.sixLevel]: '6级',
};

const disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassification = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

const disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassificationText = {
  [disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassification.small]: '小',
  [disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassification.middle]: '中',
  [disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassification.large]: '大',
};

const disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassification = {
  fromLeftToRight: 'fromLeftToRight',
  fromRightToLeft: 'fromRightToLeft',
  randomMotion: 'randomMotion',
};

const disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassificationText = {
  [disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassification.fromLeftToRight]: '从左到右',
  [disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassification.fromRightToLeft]: '从右到左',
  [disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassification.randomMotion]: '随机运动',
};

const disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassification = {
  fast: 'fast',
  middle: 'middle',
  slow: 'slow',
};

const disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassificationText = {
  [disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassification.fast]: '快',
  [disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassification.middle]: '中',
  [disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassification.slow]: '慢',
};

const disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassification = {
  red: 'red',
  green: 'green',
  alternatingRedAndGreen: 'alternatingRedAndGreen',
};

const disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassificationText = {
  [disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassification.red]: '固定红色',
  [disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassification.green]: '固定绿色',
  [disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassification.alternatingRedAndGreen]: '红绿交替',
};

const fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassification.threeMin]: '3min',
  [fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassification.fiveMin]: '5min',
};

const fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassification = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

const fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassification.small]: '小',
  [fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassification.middle]: '中',
  [fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassification.large]: '大',
};

const fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassification = {
  most: 'most',
  middle: 'middle',
  less: 'less',
};

const fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassification.most]: '多',
  [fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassification.middle]: '中',
  [fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassification.less]: '少',
};

const fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassification = {
  fishHook: 'most',
  fishingNet: 'fishingNet',
};

const fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassification.fishHook]: '钓钩',
  [fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassification.fishingNet]: '抄网',
};

const fineVisionTrainingFishingTrainingShoalStatusFourLevelClassification = {
  static: 'static',
  dynamic: 'dynamic',
};

const fineVisionTrainingFishingTrainingShoalStatusFourLevelClassificationText = {
  [fineVisionTrainingFishingTrainingShoalStatusFourLevelClassification.static]: '静态',
  [fineVisionTrainingFishingTrainingShoalStatusFourLevelClassification.dynamic]: '动态',
};

const fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassification = {
  leftEye: 'leftEye',
  rightEye: 'rightEye',
  bothEyes: 'bothEyes',
};

const fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassificationText = {
  [fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassification.leftEye]: '左眼',
  [fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassification.rightEye]: '右眼',
  [fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassification.bothEyes]: '双眼',
};

const fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassification = {
  threeMin: 'threeMin',
  fiveMin: 'fiveMin',
};

const fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassificationText = {
  [fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassification.threeMin]: '3min',
  [fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassification.fiveMin]: '5min',
};

const fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification = {
  firstLevel: 'firstLevel',
  secondLevel: 'secondLevel',
  threeLevel: 'threeLevel',
  fourLevel: 'fourLevel',
  fiveLevel: 'fiveLevel',
  sixLevel: 'sixLevel',
  sevenLevel: 'sevenLevel',
  eightLevel: 'eightLevel',
  nineLevel: 'nineLevel',
  tenLevel: 'tenLevel',
};

const fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassificationText = {
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.firstLevel]: '1级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.secondLevel]: '2级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.threeLevel]: '3级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.fourLevel]: '4级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.fiveLevel]: '5级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.sixLevel]: '6级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.sevenLevel]: '7级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.eightLevel]: '8级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.nineLevel]: '9级',
  [fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification.tenLevel]: '10级',
};

const fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassification = {
  most: 'most',
  middle: 'middle',
  less: 'less',
};

const fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassificationText = {
  [fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassification.most]: '多',
  [fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassification.middle]: '中',
  [fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassification.less]: '少',
};

const fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassification = {
  wide: 'wide',
  middle: 'middle',
  thin: 'thin',
};

const fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassificationText = {
  [fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassification.wide]: '粗',
  [fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassification.middle]: '中',
  [fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassification.thin]: '细',
};

const visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassification = {
  primary: 'primary',
  // intermediate: 'intermediate',
  advanced: 'advanced',
};

const visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassificationText = {
  [visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassification.primary]: '初级',
  // [visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassification.intermediate]: '中级',
  [visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassification.advanced]: '高级',
};

const visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassification = {
  primary: 'primary',
  advanced: 'advanced',
};

const visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassificationText = {
  [visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassification.primary]: '初级',
  [visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassification.advanced]: '高级',
};

/* 四级分类 结束 */

const fixedTrainingTime = {
  amplitudeAdjustmentTrainingTrainingTypeComprehensive: '*3min',
  conditioningFlexibilityTrainingTrainingTypeComprehensive: '*3min',
  lensReadingTrainingTrainingTypeComprehensive: '*3min',
  staticImageFusionTrainingTrainingTypeCollectiveTraining: '*5min',
  staticImageFusionTrainingTrainingTypeSpreadOutTraining: '*5min',
  dynamicImageFusionTrainingTrainingTypeCollectiveTraining: '*5min',
  dynamicImageFusionTrainingTrainingTypeSpreadOutTraining: '*5min',
  comprehensiveImageFusionTraining: '*5min',
  disinhibitionTrainingTrainingTypeRedAndGreenReadingUnits: '*5min',
  disinhibitionTrainingTrainingTypeCaptureTraining: '*5min',
  eyeMuscleTraining: '*5min',
  saccadeTraining: '*5min',
  visualFocusTrainingTrackingTrainingGuideTrackingTraining: '*3min',
  visualFocusTrainingTrackingTrainingAutonomyTrackingTraining: '*5min',
  visualFocusTrainingTrainingTypeContrastReading: '*3min',
  visualFocusTrainingTrainingTypeFindDifferent: '*5min',
};

export default {
  primaryClassification,
  primaryClassificationText,
  conditioningTrainingSecondaryClassification,
  conditioningTrainingSecondaryClassificationText,
  fusionTrainingSecondaryClassification,
  fusionTrainingSecondaryClassificationText,
  amblyopiaTrainingSecondaryClassification,
  amblyopiaTrainingSecondaryClassificationText,
  fixationTrainingSecondaryClassification,
  fixationTrainingSecondaryClassificationText,
  amplitudeAdjustmentTrainingSecondarySubTitle,
  amplitudeAdjustmentTrainingSecondarySubTitleText,
  conditioningFlexibilityTrainingSecondarySubTitle,
  conditioningFlexibilityTrainingSecondarySubTitleText,
  lensReadingTrainingSecondarySubTitle,
  lensReadingTrainingSecondarySubTitleText,
  staticImageFusionTrainingSecondarySubTitle,
  staticImageFusionTrainingSecondarySubTitleText,
  dynamicImageFusionTrainingSecondarySubTitle,
  dynamicImageFusionTrainingSecondarySubTitleText,
  disinhibitionTrainingSecondarySubTitle,
  disinhibitionTrainingSecondarySubTitleText,
  redFlashTrainingSecondarySubTitle,
  redFlashTrainingSecondarySubTitleText,
  gratingTrainingSecondarySubTitle,
  gratingTrainingSecondarySubTitleText,
  fineVisionTrainingSecondarySubTitle,
  fineVisionTrainingSecondarySubTitleText,
  eyeMuscleTrainingSecondarySubTitle,
  eyeMuscleTrainingSecondarySubTitleText,
  saccadeTrainingSecondarySubTitle,
  saccadeTrainingSecondarySubTitleText,
  visualFocusTrainingSecondarySubTitle,
  visualFocusTrainingSecondarySubTitleText,
  amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassification,
  amplitudeAdjustmentTrainingTrainingTypeThreeLevelClassificationText,
  conditioningFlexibilityTrainingTrainingTypeThreeLevelClassification,
  conditioningFlexibilityTrainingTrainingTypeThreeLevelClassificationText,
  lensReadingTrainingTrainingTypeThreeLevelClassification,
  lensReadingTrainingTrainingTypeThreeLevelClassificationText,
  staticImageFusionTrainingTrainingTypeThreeLevelClassification,
  staticImageFusionTrainingTrainingTypeThreeLevelClassificationText,
  dynamicImageFusionTrainingTrainingTypeThreeLevelClassification,
  dynamicImageFusionTrainingTrainingTypeThreeLevelClassificationText,
  disinhibitionTrainingTrainingTypeThreeLevelClassification,
  disinhibitionTrainingTrainingTypeThreeLevelClassificationText,
  redFlashTrainingTrainingEyeThreeLevelClassification,
  redFlashTrainingTrainingEyeThreeLevelClassificationText,
  redFlashTrainingTrainingDurationThreeLevelClassification,
  redFlashTrainingTrainingDurationThreeLevelClassificationText,
  redFlashTrainingTemporalFrequencyThreeLevelClassification,
  redFlashTrainingTemporalFrequencyThreeLevelClassificationText,
  redFlashTrainingTrainingModeThreeLevelClassification,
  redFlashTrainingTrainingModeThreeLevelClassificationText,
  gratingTrainingTrainingEyeThreeLevelClassification,
  gratingTrainingTrainingEyeThreeLevelClassificationText,
  gratingTrainingTrainingDurationThreeLevelClassification,
  gratingTrainingTrainingDurationThreeLevelClassificationText,
  gratingTrainingSpatialFrequencyThreeLevelClassification,
  gratingTrainingSpatialFrequencyThreeLevelClassificationText,
  gratingTrainingTrainingModeThreeLevelClassification,
  gratingTrainingTrainingModeThreeLevelClassificationText,
  fineVisionTrainingTrainingTypeThreeLevelClassification,
  fineVisionTrainingTrainingTypeThreeLevelClassificationText,
  eyeMuscleTrainingMotionModeThreeLevelClassification,
  eyeMuscleTrainingMotionModeThreeLevelClassificationText,
  eyeMuscleTrainingMotionSpeedThreeLevelClassification,
  eyeMuscleTrainingMotionSpeedThreeLevelClassificationText,
  saccadeTrainingFontSizeThreeLevelClassification,
  saccadeTrainingFontSizeThreeLevelClassificationText,
  saccadeTrainingTrainingProgramThreeLevelClassification,
  saccadeTrainingTrainingProgramThreeLevelClassificationText,
  visualFocusTrainingTrainingTypeThreeLevelClassification,
  visualFocusTrainingTrainingTypeThreeLevelClassificationText,
  amplitudeAdjustmentTrainingNormalThreeSubTitle,
  amplitudeAdjustmentTrainingNormalThreeSubTitleText,
  amplitudeAdjustmentTrainingComprehensiveThreeSubTitle,
  amplitudeAdjustmentTrainingComprehensiveThreeSubTitleText,
  amplitudeAdjustmentTrainingSceneThreeSubTitle,
  amplitudeAdjustmentTrainingSceneThreeSubTitleText,
  conditioningFlexibilityTrainingNormalThreeSubTitle,
  conditioningFlexibilityTrainingNormalThreeSubTitleText,
  conditioningFlexibilityTrainingComprehensiveThreeSubTitle,
  conditioningFlexibilityTrainingComprehensiveThreeSubTitleText,
  conditioningFlexibilityTrainingSceneThreeSubTitle,
  conditioningFlexibilityTrainingSceneThreeSubTitleText,
  lensReadingTrainingNormalThreeSubTitle,
  lensReadingTrainingNormalThreeSubTitleText,
  lensReadingTrainingComprehensiveThreeSubTitle,
  lensReadingTrainingComprehensiveThreeSubTitleText,
  dynamicImageFusionTrainingCollectiveTrainingThreeSubTitle,
  dynamicImageFusionTrainingCollectiveTrainingThreeSubTitleText,
  dynamicImageFusionTrainingSpreadOutTrainingThreeSubTitle,
  dynamicImageFusionTrainingSpreadOutTrainingThreeSubTitleText,
  disinhibitionTrainingRedAndGreenReadingUnitsThreeSubTitle,
  disinhibitionTrainingRedAndGreenReadingUnitsThreeSubTitleText,
  disinhibitionTrainingCaptureTrainingThreeSubTitle,
  disinhibitionTrainingCaptureTrainingThreeSubTitleText,
  fineVisionTrainingFishingTrainingThreeSubTitle,
  fineVisionTrainingFishingTrainingThreeSubTitleText,
  fineVisionTrainingBeadingTrainingThreeSubTitle,
  fineVisionTrainingBeadingTrainingThreeSubTitleText,
  visualFocusTrainingTrackingTrainingThreeSubTitle,
  visualFocusTrainingTrackingTrainingThreeSubTitleText,
  amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassification,
  amplitudeAdjustmentTrainingNormalTrainingEyeFourLevelClassificationText,
  amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassification,
  amplitudeAdjustmentTrainingNormalVisualMarkLevelFourLevelClassificationText,
  amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassification,
  amplitudeAdjustmentTrainingNormalTrainingDurationFourLevelClassificationText,
  amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassification,
  amplitudeAdjustmentTrainingNormalInitialTrainingDifficultyFourLevelClassificationText,
  amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassification,
  amplitudeAdjustmentTrainingComprehensiveTrainingEyeFourLevelClassificationText,
  amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassification,
  amplitudeAdjustmentTrainingSceneTrainingEyeFourLevelClassificationText,
  amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassification,
  amplitudeAdjustmentTrainingSceneTrainingDurationFourLevelClassificationText,
  conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassification,
  conditioningFlexibilityTrainingNormalTrainingEyeFourLevelClassificationText,
  conditioningFlexibilityTrainingNormalVisualMarkLevelFourLevelClassification,
  conditioningFlexibilityTrainingNormalVisualMarkLevelFourLevelClassificationText,
  conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassification,
  conditioningFlexibilityTrainingNormalTrainingDurationFourLevelClassificationText,
  conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassification,
  conditioningFlexibilityTrainingNormalInitialTrainingDifficultyFourLevelClassificationText,
  conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassification,
  conditioningFlexibilityTrainingNormalPositiveOrNegativeLensReinforcementFourLevelClassificationText,
  conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassification,
  conditioningFlexibilityTrainingComprehensiveTrainingEyeFourLevelClassificationText,
  conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassification,
  conditioningFlexibilityTrainingSceneTrainingEyeFourLevelClassificationText,
  conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassification,
  conditioningFlexibilityTrainingSceneTrainingDurationFourLevelClassificationText,
  lensReadingTrainingNormalLensTypeFourLevelClassification,
  lensReadingTrainingNormalLensTypeFourLevelClassificationText,
  lensReadingTrainingNormalTrainingEyeFourLevelClassification,
  lensReadingTrainingNormalTrainingEyeFourLevelClassificationText,
  lensReadingTrainingNormalVisualMarkLevelFourLevelClassification,
  lensReadingTrainingNormalVisualMarkLevelFourLevelClassificationText,
  lensReadingTrainingNormalTrainingDurationFourLevelClassification,
  lensReadingTrainingNormalTrainingDurationFourLevelClassificationText,
  lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassification,
  lensReadingTrainingNormalInitialTrainingDifficultyFourLevelClassificationText,
  lensReadingTrainingComprehensiveLensTypeFourLevelClassification,
  lensReadingTrainingComprehensiveLensTypeFourLevelClassificationText,
  lensReadingTrainingComprehensiveTrainingEyeFourLevelClassification,
  lensReadingTrainingComprehensiveTrainingEyeFourLevelClassificationText,
  dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassification,
  dynamicImageFusionTrainingCollectiveTrainingMoveLevelFourLevelClassificationText,
  dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassification,
  dynamicImageFusionTrainingSpreadOutTrainingMoveLevelFourLevelClassificationText,
  disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassification,
  disinhibitionTrainingRedAndGreenReadingUnitsInitialFontSizeLevelFourLevelClassificationText,
  disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassification,
  disinhibitionTrainingCaptureTrainingInitialSizeFourLevelClassificationText,
  disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassification,
  disinhibitionTrainingCaptureTrainingButterflyMovementModeFourLevelClassificationText,
  disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassification,
  disinhibitionTrainingCaptureTrainingButterflyMovementSpeedFourLevelClassificationText,
  disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassification,
  disinhibitionTrainingCaptureTrainingNetPocketColorFourLevelClassificationText,
  fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassification,
  fineVisionTrainingFishingTrainingTrainingEyeFourLevelClassificationText,
  fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassification,
  fineVisionTrainingFishingTrainingTrainingDurationFourLevelClassificationText,
  fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassification,
  fineVisionTrainingFishingTrainingInitialShoalSizeFourLevelClassificationText,
  fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassification,
  fineVisionTrainingFishingTrainingInitialShoalPopulationFourLevelClassificationText,
  fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassification,
  fineVisionTrainingFishingTrainingFishingMethodsFourLevelClassificationText,
  fineVisionTrainingFishingTrainingShoalStatusFourLevelClassification,
  fineVisionTrainingFishingTrainingShoalStatusFourLevelClassificationText,
  fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassification,
  fineVisionTrainingBeadingTrainingTrainingEyeFourLevelClassificationText,
  fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassification,
  fineVisionTrainingBeadingTrainingTrainingDurationFourLevelClassificationText,
  fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassification,
  fineVisionTrainingBeadingTrainingInitialBeadedSizeFourLevelClassificationText,
  fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassification,
  fineVisionTrainingBeadingTrainingBeadedPopulationFourLevelClassificationText,
  fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassification,
  fineVisionTrainingBeadingTrainingNeedleDiameterFourLevelClassificationText,
  visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassification,
  visualFocusTrainingTrackingTrainingGuideTrackingTrainingFourLevelClassificationText,
  visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassification,
  visualFocusTrainingTrackingTrainingAutonomyTrackingTrainingFourLevelClassificationText,
  fixedTrainingTime,
};
